.legend {
  background-color: var(--white);
  margin: 10px auto;
  overflow: auto;
  white-space: nowrap;
}

.legendItem {
  padding: 6px;
  display: inline-block;
}

.legendItemIcon {
  margin-right: 6px;
  height: 25px;
  display: inline;
}
