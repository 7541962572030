/* Cascading Device Queries */

.results {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 125px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

div.loadingMask {
  display: block;
  text-align: center;
  margin: 2rem 0;
}

.versionAndCopyrightInfo {
  display: block;
}

.fixedVersionAndCopyrightInfo {
  composes: versionAndCopyrightInfo;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.floatRight {
  float: right;
}

@media (max-width: 767px) {
  .mobile {
    display: inline;
    display: initial;
  }

  .nonMobile {
    display: none;
  }

  .versionAndCopyrightInfo {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }

  .nonMobile {
    display: inline;
    display: initial;
  }
}
