.stationListItem {
  padding: 1rem;
  margin: 0.5rem;
}

.right {
  float: right;
}

.logo {
  max-width: 60px !important;
  float: right;
}

.bottomContent {
  border-top: 1px solid var(--borderGrey);
  padding: 1rem 0;
}

.statusIcons {
  float: left;
}

.logo::after,
.right::after {
  clear: both;
}

.updateLinkContainer {
  display: inline-block;
  line-height: 2rem;
  vertical-align: middle;
  margin-top: -1rem;
}
