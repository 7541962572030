.navbar {
  background-color: white;
  padding: 10px 10px 5px;
  border-bottom: 1px solid var(--borderGrey);
}

.logo {
  display: inline;
  height: 40px;
}
